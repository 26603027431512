<template>
  <div class="flex items-baseline space-x-2">
    <button
      class="text-copy transition-colors duration-200 ease-out md:text-base md:leading-5"
      :class="{
        'text-grey-darker hover:text-white':
          themeColor !== 'white' && selectedLanguage !== 'EN',
        'text-grey-dark hover:text-black':
          themeColor === 'white' && selectedLanguage !== 'EN',
        'text-black': themeColor === 'white' && selectedLanguage === 'EN',
        'text-white': themeColor !== 'white' && selectedLanguage === 'EN',
        'pointer-events-none': selectedLanguage === 'EN',
      }"
      @click="selectedLanguage = 'EN'"
    >
      EN
    </button>

    <p class="text-copy h-3 border border-grey-medium"></p>

    <button
      class="text-copy transition-colors duration-200 ease-out md:text-base md:leading-5"
      :class="{
        'text-grey-darker hover:text-white':
          themeColor !== 'white' && selectedLanguage !== 'DE',
        'text-grey-dark hover:text-black':
          themeColor === 'white' && selectedLanguage !== 'DE',
        'text-black': themeColor === 'white' && selectedLanguage === 'DE',
        'text-white': themeColor !== 'white' && selectedLanguage === 'DE',
        'pointer-events-none': selectedLanguage === 'DE',
      }"
      @click="selectedLanguage = 'DE'"
    >
      DE
    </button>
  </div>
</template>

<script setup lang="ts">
defineProps<{
  themeColor?: string
}>()

const selectedLanguage = ref<'DE' | 'EN'>('DE')
</script>
